<template>
<div class="project-detail">
      <div class="project-header" v-if="detail.caseHead"
           :style="{'background-image':detail.caseHead.imgUrl?`url(${detail.caseHead.imgUrl})`:''}"
      >
          <div>
            <div class="title">项目案例</div>
            <div class="type">安软科技 | {{detail.caseTypeName}}</div>
            <div class="info" style="width: 480px">{{detail.caseHead.description}}</div>
          </div>
      </div>
      <div class="header-title" v-if="detail.caseHead">{{detail.caseHead.title}}</div>
<!--      客户背景-->
      <div class="user-info" v-if="detail.caseUserBg">
          <div class="title">/客户背景</div>
          <div class="text-info">{{detail.caseUserBg.description}}</div>
          <div class="user-bg">
            <img :src="detail.caseUserBg.imgUrl" width="100%" height="100%" alt="">
          </div>
      </div>
<!--          建设方案-->
      <div class="build-info" v-if="detail.caseBuild">
            <div class="title">/建设方案</div>
            <div class="text-info">{{detail.caseBuild.description}}</div>
            <div class="build-content">
                <div class="build-item"
                     v-for="(item,index) in detail.caseBuild.caseBuildList"
                     :key="index"
                >
                    <div v-if="index%2==0">
                          <div class="item-info">
                              <div>
                                <div class="title">{{item.title}}</div>
                                <div class="item.info" style="line-height: 26px;color: #797979">{{item.description}}</div>
                              </div>
                          </div>
                          <div class="item-img">
                            <img :src="item.imgUrl" width="100%" height="100%" alt="">
                          </div>
                    </div>
                    <div v-else>
                      <div class="item-img">
                        <img :src="item.imgUrl" width="100%" height="100%" alt="">
                      </div>
                      <div class="item-info">
                        <div>
                          <div class="title">{{item.title}}</div>
                          <div class="item.info" style="line-height: 26px;color: #797979">{{item.description}}</div>
                        </div>
                      </div>
                    </div>

                </div>
            </div>
      </div>
<!--  价值体现-->
      <div class="build-energize" v-if="detail.caseEnergize">
        <div class="title">/价值体现</div>
        <div class="text-info">{{detail.caseEnergize.description}}</div>
        <div class="energize-content">
          <div class="energize-item"
               v-for="(item,index) in detail.caseEnergize.caseEnergizeList"
               :key="index"
          >
              <div><img :src="item.icon" width="78px" height="78px"></div>
              <div style="text-align: center;margin-top: 20px">{{item.description}}</div>
          </div>
        </div>
      </div>
<!--  了解其他案例-->
  <div class="more-project">
      <div class="title">了解其他案例</div>
    <el-carousel class="project-carousel"
                 ref="projectCarousel"
                 :autoplay="false"
                 indicator-position="none"
                 height="520px"

    >
      <el-carousel-item
          v-for="(item,index) in projectList"
          :key="index"
      >
        <div
            class="project-item"
            @click="goProjectDetail(item.id)"

        >
          <div><img :src="item.casePicture" width="900px" height="400px" alt=""></div>
          <div style="margin-top: 10px;font-size: 18px;font-weight: bold;color: #797979">{{item.caseTypeName}}</div>
          <div style="margin-top: 5px;font-size: 18px;">{{item.caseName}}</div>
          <div style="margin-top: 5px;font-size: 14px;color: #797979">{{item.shufflingInfo}}</div>
          <div style="margin-top: 10px">了解详情></div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>


</div>
</template>

<script>
export default {
  name: "projectDetail",
  data(){
    return {
      detail:{},
      projectList:[],
    }
  },
  watch:{
    '$route.query.id'(){
      this.getDetail();
    }
  },
  created() {
    this.getDetail();
    this.getProjectList();
  },
  methods:{
    getDetail(){
      this.$get('/website/case/'+this.$route.query.id).then((res)=>{
        this.detail=res.data;
      }).catch((err)=>{
        this.msgError(err.msg)
      })
    },
    getProjectList(){
      this.$get('/website/case/list').then((res)=>{
        this.projectList=res.rows;
      }).catch((err)=>{
        this.msgError(err.msg)
      })
    },
    goProjectDetail(id){
      this.$router.push({
        name:'projectDetail',
        query:{
          id:id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.project-detail{
  padding-top: 64px;
  .project-header{
    width: 100%;
    height: 680px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: left;
    color: #fff;
    .title{
      font-size: 48px;
      padding-top: 150px;
      margin-bottom: 20px;
    }
    .type{
      font-size: 32px;
      margin-bottom: 10px;
    }
    .info{
      font-size: 18px;
    }
    >div{
      margin-left: 300px;
    }
  }
  .header-title{
    margin: 80px 0;
    text-align: center;
    font-size: 38px;
  }
  .user-info{
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 80px;
    .title{
      font-size: 30px;
    }
    .text-info{
      margin-top: 20px;
      color: #797979;
      font-size: 16px;
    }
    .user-bg{
      height: 600px;
      margin-top: 80px;
    }
  }
  .build-info{
    width: 1200px;
    margin: 0 auto;
    padding: 80px 0;
    .title{
      font-size: 30px;
    }
    .text-info{
      margin-top: 20px;
      font-size: 16px;
      color: #797979;
    }
    .build-content{
      margin-top: 80px;
      .build-item{
          margin-top: 128px;
          >div{
            display: flex;
            justify-content: space-between;
            height: 400px;
            .item-info{
              width: 490px;
              height: 400px;
              display: flex;
              align-items: center;
              .title{
                font-size: 24px;
                font-weight: bold;
                margin-bottom: 20px;
              }
            }
            .item-img{
              width: 600px;
              height: 400px;
            }
          }
      }
      .build-item:first-of-type{
        margin-top: 0;
      }
    }
  }
  .build-energize{
    width: 1200px;
    margin: 0 auto;
    padding: 80px 0;
    .title{
      font-size: 30px;
    }
    .text-info{
      margin-top: 20px;
      font-size: 16px;
      color: #797979;
    }
    .energize-content{
      margin-top: 80px;
      display: flex;
      justify-content: space-around;
      .energize-item{
        text-align: center;
      }
    }
  }
  .more-project{
    width: 1200px;
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 30px;
    .title{
      font-size: 38px;
      text-align: center;
      margin-bottom: 80px;
    }
  }
  .project-carousel{
    width: 900px;
    margin: 0 auto;
    .project-item{
      cursor: pointer;
    }
  }
}
</style>
